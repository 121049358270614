import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import Typography from '@mui/material/Typography';

function BannerStrap(props) {
  const { data } = props;
  return (
    <Box sx={{ backgroundColor: 'background.primary' }} py={4}>
      <ContainerDefault>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {data.prismicGlobalLayout.data.strapline && (
            <Box display={{ xs: 'none', md: 'flex' }}>
              <Typography component="p" variant="body2" color="textPrimary">
                {data.prismicGlobalLayout.data.strapline}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            {data.prismicGlobalLayout.data.telephone_number && (
              <Box>
                <Typography
                  component="p"
                  variant="body2"
                  color="textPrimary"
                  style={{ textDecoration: 'underline' }}
                >
                  {data.prismicGlobalLayout.data.telephone_number}
                </Typography>
              </Box>
            )}
            {data.prismicGlobalLayout.data.email_address && (
              <Box mx={4}>
                <Typography
                  component="a"
                  href={`mailto:${data.prismicGlobalLayout.data.email_address}`}
                  variant="body2"
                  color="textPrimary"
                  style={{ display: 'block' }}
                >
                  Email us
                </Typography>
              </Box>
            )}
            {data.prismicGlobalLayout.data.social_media_links &&
              data.prismicGlobalLayout.data.social_media_links.length > 0 && (
                <Box display="flex">
                  {data.prismicGlobalLayout.data.social_media_links.map(
                    (item, key, arr) => {
                      const marginRight = arr.length - 1 !== key ? 1 : 0;
                      return (
                        <Box
                          key={key}
                          component="a"
                          target="_blank"
                          display="flex"
                          mr={marginRight}
                          href={item.link.url}
                        >
                          <img
                            src={item.image.url}
                            alt={item.image.alt ? item.image.alt : ''}
                          />
                        </Box>
                      );
                    }
                  )}
                </Box>
              )}
          </Box>
        </Box>
      </ContainerDefault>
    </Box>
  );
}

BannerStrap.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BannerStrap;
