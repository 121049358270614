import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import linkResolver from 'helpers/linkResolver';

function Nav(props) {
  const { mobile } = props;
  const textVariant = mobile ? 'navitemmobile' : 'navitem';
  return (
    <StaticQuery
      query={graphql`
        query MainMenuQuery {
          prismicGlobalLayout {
            data {
              main_navigation {
                link_text
                link {
                  uid
                  type
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Box display="flex">
          {data.prismicGlobalLayout.data.main_navigation && (
            <React.Fragment>
              {data.prismicGlobalLayout.data.main_navigation.map(
                (item, key) => {
                  return (
                    <Box key={key} mx={{ xs: 1, md: 4 }}>
                      <Typography
                        component={Link}
                        variant={textVariant}
                        sx={{
                          color: 'text.white',
                          textDecoration: 'none',
                        }}
                        to={item.link ? linkResolver(item.link) : ''}
                      >
                        {item.link_text}
                      </Typography>
                    </Box>
                  );
                }
              )}
            </React.Fragment>
          )}
        </Box>
      )}
    />
  );
}

Nav.propTypes = {
  mobile: PropTypes.bool,
};

export default Nav;
