import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BlockContainer, ContainerDefault } from 'components/containers';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import linkResolver from 'helpers/linkResolver';

function ListingBlock(props) {
  const { slice } = props;
  const data = slice.primary;
  const items = slice.items;
  return (
    <BlockContainer>
      <ContainerDefault maxWidth="lg">
        {data.title && data.title.text && (
          <Box mb={15}>
            <Typography
              component="p"
              variant="h1"
              color="text.secondary"
              align="center"
            >
              {data.title.text}
            </Typography>
          </Box>
        )}
        {data.display_no_current_jobs_message && (
          <Box>
            <Typography component="h2" variant="h2" align="center">
              Sorry, there are no vacancies open at this time
            </Typography>
          </Box>
        )}
        {items && !data.display_no_current_jobs_message && (
          <Box component="ul" m={0} p={0}>
            {items.map((item, key, arr) => {
              return (
                <Box
                  component="li"
                  sx={{
                    listStyle: 'none',
                    borderBottom: '1px solid',
                    borderBottomColor: 'primary.main',
                  }}
                  pb={3}
                  mb={3}
                  key={key}
                >
                  {item.link?.uid && (
                    <Box
                      component={Link}
                      display="flex"
                      alignItems="center"
                      sx={{
                        textDecoration: 'none',
                      }}
                      to={linkResolver(item.link.uid ? item.link : '')}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          color="text.secondary"
                        >
                          {item.list_item_text.text}
                        </Typography>
                      </Box>
                      <Box ml={10}>
                        <ArrowForwardIosIcon sx={{ color: 'text.secondary' }} />
                      </Box>
                    </Box>
                  )}

                  {item.link?.raw?.link_type == 'Web' && (
                    <Box
                      component="a"
                      display="flex"
                      alignItems="center"
                      sx={{
                        textDecoration: 'none',
                      }}
                      href={item.link?.raw?.url}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          color="text.secondary"
                        >
                          {item.list_item_text.text}
                        </Typography>
                      </Box>
                      <Box ml={10}>
                        <ArrowForwardIosIcon sx={{ color: 'text.secondary' }} />
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </ContainerDefault>
    </BlockContainer>
  );
}

ListingBlock.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ListingBlock;
