import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import Typography from '@mui/material/Typography';
import linkResolver from 'helpers/linkResolver';

function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterNavQuery {
          prismicGlobalLayout {
            data {
              footer_navigation {
                link_text
                link {
                  uid
                  type
                }
              }
            }
          }
        }
      `}
      render={data => {
        const menuItems = data.prismicGlobalLayout.data.footer_navigation;
        if (!menuItems) return null;
        return (
          <ContainerDefault>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={10}
            >
              <Box mx={2}>
                <Typography component="p" variant="body3" color="text.primary">
                  &copy; Copyright {new Date().getFullYear()} RM Contractors
                </Typography>
              </Box>
              {menuItems && (
                <React.Fragment>
                  <Box
                    sx={{
                      width: '1px',
                      height: '15px',
                      backgroundColor: 'black',
                    }}
                  />
                  {menuItems.map((item, key, arr) => {
                    return (
                      <React.Fragment key={key}>
                        <Box mx={2}>
                          <Link
                            to={linkResolver(item.link ? item.link : null)}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Typography
                              component="p"
                              variant="body3"
                              color="text.primary"
                            >
                              {item.link_text}
                            </Typography>
                          </Link>
                        </Box>
                        {arr.length - 1 !== key && (
                          <Box
                            sx={{
                              width: '1px',
                              height: '15px',
                              backgroundColor: 'black',
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
            </Box>
          </ContainerDefault>
        );
      }}
    />
  );
}

export default Footer;
