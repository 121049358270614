import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { ContainerDefault, GridContainer } from 'components/containers';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Image, GradientOverlay } from 'components/ui';

import linkResolver from 'helpers/linkResolver';

function ThumbnailGridImages(props) {
  const { slice } = props;

  const colSpacing = slice.primary?.column_space
    ? slice.primary?.column_space
    : 4;

  const showOverlay = slice.primary.show_gradient_overlays_on_images
    ? true
    : false;

  const overlayText = slice.primary.text_position ? true : false;

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <Box bgcolor="background.primary" py={20}>
      <ContainerDefault>
        {slice.primary && slice.primary.title && slice.primary.title.text && (
          <Box mb={10}>
            <Typography
              component="h2"
              variant="h1"
              color="textSecondary"
              align="center"
            >
              {slice.primary.title.text}
            </Typography>
          </Box>
        )}
        {slice.items && slice.items.length > 0 && (
          <GridContainer spacing={4}>
            {slice.items.map((item, key) => {
              return (
                <Grid item xs={12} md={colSpacing} key={key}>
                  <ConditionalWrapper
                    condition={item.link.uid}
                    wrapper={children => (
                      <Link to={linkResolver(item.link ? item.link : null)}>
                        {children}
                      </Link>
                    )}
                  >
                    <React.Fragment>
                      <Box
                        position="relative"
                        display={overlayText ? 'flex' : 'block'}
                        alignItems="flex-end"
                      >
                        {showOverlay && <GradientOverlay />}
                        <Image
                          src={`${item.image.url}&ar=1:1&fit=crop`}
                          alt={item.image.alt}
                        />
                        {item.text && item.text.raw && (
                          <Box
                            position={overlayText ? 'absolute' : 'relative'}
                            width="100%"
                            p={overlayText ? 5 : 0}
                          >
                            <Typography
                              component="div"
                              variant={overlayText ? 'h2' : 'h4'}
                              sx={{
                                color: overlayText
                                  ? 'text.white'
                                  : 'text.black',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.text.html,
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </React.Fragment>
                  </ConditionalWrapper>
                </Grid>
              );
            })}
          </GridContainer>
        )}
      </ContainerDefault>
    </Box>
  );
}

ThumbnailGridImages.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ThumbnailGridImages;
