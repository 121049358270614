import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContainerDefault, GridContainer } from 'components/containers';
import { Image, RichTextField } from 'components/ui';
import { Typography } from '@mui/material';

function TextAndImage(props) {
  const { slice } = props;
  const data = slice.primary;
  const verticalAlign =
    data.vertical_alignment == 'center'
      ? data.vertical_alignment
      : 'flex-start';

  const orderCol = data.image_alignment == 'right' ? 1 : 0;
  const justify = data.image_alignment == 'right' ? 'flex-end' : 'flex-start';

  const contentPaddingRight = data.image_alignment == 'right' ? 20 : 0;
  const contentPaddingLeft = data.image_alignment == 'right' ? 0 : 20;

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? (
      wrapper(children)
    ) : (
      <Box bgcolor="background.primary" py={15}>
        <ContainerDefault>{children}</ContainerDefault>
      </Box>
    );

  return (
    <ConditionalWrapper
      condition={!data.add_block_background_color}
      wrapper={children => <ContainerDefault>{children}</ContainerDefault>}
    >
      {data.title && data.title.text && (
        <Box mb={15}>
          <Typography
            component="p"
            variant="h1"
            color="text.secondary"
            align="center"
          >
            {data.title.text}
          </Typography>
        </Box>
      )}
      <GridContainer
        spacing={0}
        alignItems={verticalAlign}
        justifyContent={justify}
      >
        <Grid item xs={12} lg={6} order={orderCol}>
          {data.image && data.image.url && (
            <Image src={data.image.url} alt={data.image.alt} />
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          {data.content && data.content.raw && (
            <Box
              pr={{
                xs: 0,
                lg: contentPaddingRight,
              }}
              pl={{
                xs: 0,
                lg: contentPaddingLeft,
              }}
            >
              <RichTextField data={data.content.raw} />
            </Box>
          )}
        </Grid>
      </GridContainer>
    </ConditionalWrapper>
  );
}

TextAndImage.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextAndImage;
