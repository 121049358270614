import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ContainerDefault } from 'components/containers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Image } from 'components/ui';
import { GridContainer } from 'components/containers';

function Instagram() {
  return <div></div>;
  /*return (
    <StaticQuery
      query={graphql`
        query InstaQuery {
          allInstagramContent(
            sort: { fields: timestamp, order: DESC }
            limit: 8
          ) {
            edges {
              node {
                media_url
                media_type
                caption
                permalink
              }
            }
          }
        }
      `}
      render={data => {
        if (!data.allInstagramContent.edges) return null;
        return (
          <Box bgcolor="background.primary" pb={20}>
            <ContainerDefault>
              <Box mb={10}>
                <Typography
                  component="h2"
                  variant="h1"
                  color="textSecondary"
                  align="center"
                >
                  Instagram
                </Typography>
              </Box>
              <GridContainer spacing={4}>
                <React.Fragment>
                  {data.allInstagramContent.edges.map((item, key) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} key={key}>
                        <a
                          href={item.node.permalink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            src={item.node.media_url}
                            alt={item.node.caption ? item.node.caption : ''}
                          />
                        </a>
                      </Grid>
                    );
                  })}
                </React.Fragment>
              </GridContainer>
            </ContainerDefault>
          </Box>
        );
      }}
    />
  );
  */
}

export default Instagram;
