import React from 'react';
import PropTypes from 'prop-types';
import { Spacer } from 'components/containers';
import {
  TextBlock,
  Statistics,
  Instagram,
  ThumbnailGridImages,
  FullWidthImage,
  InlineImages,
  TextAndImage,
  ListingBlock,
  JobDescription,
  SiteOperativeDatabaseForm,
  ImageCarousel,
} from 'components/slices';

function ResolveSlices(props) {
  const { data } = props;

  const sliceComponents = {
    text_block: TextBlock,
    statistics: Statistics,
    instagram: Instagram,
    thumbnail_grid_images: ThumbnailGridImages,
    full_width_image: FullWidthImage,
    inline_images: InlineImages,
    text___image: TextAndImage,
    listing_block: ListingBlock,
    job_description: JobDescription,
    site_operative_database_form: SiteOperativeDatabaseForm,
    image_carousel: ImageCarousel,
  };

  const sliceZoneContent = data.map((slice, index, arr) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    var spacingTop = process.env.DEFAULT_SPACER_VALUE;
    var spacingBottom = process.env.DEFAULT_SPACER_VALUE;
    if (slice.primary && slice.primary.spacer_top !== null) {
      spacingTop = slice.primary.spacer_top;
    }
    if (slice.primary && slice.primary.spacer_bottom !== null) {
      spacingBottom = slice.primary.spacer_bottom;
    }
    if (SliceComponent) {
      return (
        <Spacer
          key={`slice-${slice.slice_type}-${index}`}
          spacer_top={parseInt(spacingTop)}
          spacer_bottom={parseInt(spacingBottom)}
        >
          <SliceComponent slice={slice} />
        </Spacer>
      );
    }
    return null;
  });

  return <main className="container">{sliceZoneContent}</main>;
}

ResolveSlices.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ResolveSlices;
