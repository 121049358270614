import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import { Image } from 'components/ui';

function InlineImages(props) {
  const { slice } = props;
  if (!slice.items) return null;
  return (
    <ContainerDefault>
      <Box display="flex" alignItems="center" justifyContent="center">
        {slice.items.map((item, key) => {
          const width = key === 1 ? 150 : 200; // Set width to 150 for the second item, otherwise 200
          return (
            <Box key={key} mx={[4, 6, 10]}>
              <Image
                src={`${item.image.url}&w=${width}`}
                alt={item.image.alt}
              />
            </Box>
          );
        })}
      </Box>
    </ContainerDefault>
  );
}

InlineImages.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default InlineImages;
