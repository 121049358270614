import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Image, GradientOverlay, RichTextField } from 'components/ui';

function FullWidthImage(props) {
  const { slice } = props;
  const data = slice.primary;

  var vertAlign = 'flex-end';
  if (data.align_text == 'Center') {
    vertAlign = 'center';
  }
  if (data.align_text == 'Top') {
    vertAlign = 'flex-start';
  }

  return (
    <Box
      position="relative"
      display={{ xs: 'block', md: 'flex' }}
      justifyContent="center"
      alignItems={vertAlign}
    >
      <Box display={{ xs: 'none', md: 'block' }}>
        <GradientOverlay gradient="linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.396796218487395) 100%)" />
      </Box>
      {data.content && data.content.raw && (
        <Box
          position={{ xs: 'relative', md: 'absolute' }}
          sx={{ textAlign: 'center' }}
          pb={10}
        >
          <RichTextField data={data.content.raw} />
        </Box>
      )}
      <Image src={data.image.url} alt={data.image.alt} />
    </Box>
  );
}

FullWidthImage.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default FullWidthImage;
