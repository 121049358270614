import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';

function TextBlock(props) {
  const { slice } = props;
  const data = slice.primary;
  const textAlignment = data.align ? data.align : 'left';

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? (
      wrapper(children)
    ) : (
      <Box bgcolor="background.primary" py={15}>
        <ContainerDefault maxWidth="lg">{children}</ContainerDefault>
      </Box>
    );

  return (
    <ConditionalWrapper
      condition={!data.add_block_background_color}
      wrapper={children => (
        <ContainerDefault maxWidth="lg">{children}</ContainerDefault>
      )}
    >
      <Box sx={{ textAlign: textAlignment }}>
        <RichTextField data={data.content.raw} />
      </Box>
    </ConditionalWrapper>
  );
}

TextBlock.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextBlock;
