import React from 'react';
import PropTypes from 'prop-types';
import { ContainerDefault } from 'components/containers';
import { JobDataBase } from 'components/forms';

function SiteOperativeDatabaseForm() {
  return (
    <ContainerDefault maxWidth="md">
      <JobDataBase />
    </ContainerDefault>
  );
}

export default SiteOperativeDatabaseForm;
