import React from 'react';
import PropTypes from 'prop-types';
import { ContainerDefault } from 'components/containers';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CountUp from 'react-countup';
import { InView } from 'react-intersection-observer';

function StatisticVal(props) {
  const { val, extension } = props;
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => {
        return (
          <div ref={ref}>
            <Typography variant="h2" sx={{ color: 'text.secondary' }}>
              {inView ? (
                <CountUp start={0} end={val} duration="2" />
              ) : (
                <span>0</span>
              )}{' '}
              {extension}
            </Typography>
          </div>
        );
      }}
    </InView>
  );
}

function Statistics(props) {
  const { slice } = props;
  const data = slice.items;

  return (
    <ContainerDefault maxWidth="xl">
      <Grid container spacing={8}>
        {data.map((item, key) => {
          return (
            <Grid item xs={12} sm={4} key={key}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                {item.statistic_value && (
                  <React.Fragment>
                    <Box>
                      <StatisticVal
                        val={item.statistic_value}
                        extension={item.statistic_extension}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="h2"
                        sx={{ color: 'text.grey' }}
                        align="center"
                      >
                        {item.statistic_footer_text}
                      </Typography>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </ContainerDefault>
  );
}

Statistics.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Statistics;
