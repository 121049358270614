import React from 'react';
import PropTypes from 'prop-types';
import { BlockContainer, ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function JobDescription(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <BlockContainer>
      <ContainerDefault maxWidth="md">
        <Box display="flex" justifyContent="space-between" mb={6}>
          {data.job_title && (
            <Box>
              <Typography component="p" variant="h5" color="text.secondary">
                Job Title: {data.job_title}
              </Typography>
            </Box>
          )}
          {data.job_location && (
            <Box>
              <Typography component="p" variant="h5" color="text.secondary">
                Job Location: {data.job_location}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {data.content && data.content.raw && (
            <RichTextField data={data.content.raw} />
          )}
        </Box>
      </ContainerDefault>
    </BlockContainer>
  );
}

JobDescription.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default JobDescription;
