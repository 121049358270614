import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { StaticQuery, graphql } from 'gatsby';
import { Context } from 'store/provider';
import styled from 'styled-components';
import Nav from 'components/header/nav';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContainerDefault } from 'components/containers';
import { BtnDefault } from 'components/ui';
import Brand from 'images/brand.svg';
import { useTransition, animated, config, useSpring } from 'react-spring';
import BannerStrap from './BannerStrap';

export const StyledSlider = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  position: relative;
  min-height: 500px;
  overflow: hidden;
`;

function Header(props) {
  const { isFront, image, multibanner, multiimages } = props;
  const [index, set] = useState(0);
  const [state, dispatch] = useContext(Context);

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 },
  });
  useEffect(() => {
    const t = setInterval(
      () => set(state => (state + 1) % multiimages.length),
      4000
    );
    return () => clearTimeout(t);
  }, []);

  const toggleEnquiry = e => {
    e.preventDefault();
    dispatch({ type: 'SET_ENQUIRY_OVERLAY', payload: !state.enquiry_overlay });
  };

  const ConditionalWrapper = ({ condition, wrapper, children }) => {
    if (condition) {
      return wrapper(children);
    }
    return (
      <StyledSlider>
        {transitions((style, i) => (
          <animated.div
            className="transition-elm"
            style={{
              ...style,
              backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%), url(${multiimages[i].image.url})`,
            }}
          >
            {children}
          </animated.div>
        ))}
      </StyledSlider>
    );
  };

  return (
    <StaticQuery
      query={graphql`
        query TopBannerQuery {
          prismicGlobalLayout {
            data {
              telephone_number
              strapline
              email_address
              social_media_links {
                image {
                  url
                  alt
                }
                link {
                  url
                }
              }
              banner_buttons {
                link_text
                link {
                  uid
                  type
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Box>
            <BannerStrap data={data} />
            <ConditionalWrapper
              condition={!multibanner}
              wrapper={children => (
                <Box
                  sx={{
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%), url(${image.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    minHeight: isFront ? '100vh' : '380px',
                  }}
                >
                  {children}
                </Box>
              )}
            >
              <ContainerDefault>
                <Box py={6}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      component={Grid}
                      item
                      xs={6}
                      md={3}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Link to="/">
                        <Box sx={{ width: { xs: '100px', md: 'auto' } }}>
                          <Brand style={{ width: '100%' }} />
                        </Box>
                      </Link>
                    </Box>
                    <Box
                      component={Grid}
                      item
                      md={6}
                      display={{ xs: 'none', md: 'flex' }}
                      justifyContent="center"
                    >
                      <Nav />
                    </Box>
                    <Box
                      component={Grid}
                      item
                      display="flex"
                      justifyContent="flex-end"
                      xs={6}
                      md={3}
                    >
                      {data.prismicGlobalLayout.data.banner_buttons && (
                        <React.Fragment>
                          {data.prismicGlobalLayout.data.banner_buttons.map(
                            (item, key) => {
                              return (
                                <Box
                                  key={key}
                                  component="button"
                                  className="btn-reset"
                                  onClick={e => toggleEnquiry(e)}
                                >
                                  <BtnDefault data={item.link_text} />
                                </Box>
                              );
                            }
                          )}
                        </React.Fragment>
                      )}
                    </Box>
                    <Box
                      component={Grid}
                      item
                      xs={12}
                      display={{ xs: 'flex', md: 'none' }}
                      justifyContent="center"
                      pt={5}
                    >
                      <Nav mobile={true} />
                    </Box>
                  </Grid>
                </Box>
              </ContainerDefault>
            </ConditionalWrapper>
          </Box>
        );
      }}
    />
  );
}

Header.propTypes = {
  isFront: PropTypes.bool.isRequired,
  image: PropTypes.object,
  multibanner: PropTypes.bool.isRequired,
  multiimages: PropTypes.array,
};

export default Header;
