import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { RichTextField } from 'components/ui';

const Container = styled('div')(({ theme, isXsDown }) => ({
  backgroundColor: '#050a30',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: isXsDown ? '30px' : '30px 100px 50px 100px',
}));

const SampleNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      //className={className}
      style={{
        position: 'absolute',
        right: '-80px',
        top: '50%',
        transform: 'translate(0px, -50%)',
        display: 'block',
        zIndex: '10',
        background: 'transparent',
        cursor: 'pointer',
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={onClick}
    >
      <ArrowForwardIosIcon style={{ fontSize: '60px' }} />
    </div>
  );
};

const SamplePrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      //className={className}
      style={{
        position: 'absolute',
        left: '-80px',
        top: '50%',
        transform: 'translate(0px, -50%)',
        display: 'block',
        zIndex: '10',
        background: 'transparent',
        cursor: 'pointer',
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={onClick}
    >
      <ArrowBackIosIcon style={{ fontSize: '60px' }} />
    </div>
  );
};

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

const ImageCarousel = props => {
  const { slice } = props;
  const items = slice.items;

  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('lg'));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: !isXsDown ? <SampleNextArrow /> : null,
    prevArrow: !isXsDown ? <SamplePrevArrow /> : null,
  };

  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <Slider {...settings}>
        {items.map((item, index) => {
          // Log item fields to check their structure
          console.log('Item:', item);

          const title = item.title?.text || item.title;
          const teamMemberName =
            item.team_member_name?.text || item.team_member_name;
          const jobTitle = item.job_title?.text || item.job_title;
          const quote = item.quote?.raw || item.quote;

          return (
            <div key={index} style={{ height: '500px' }}>
              <Grid container>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  lg={5}
                  pl={[0, 0, 0, 5]}
                  pt={[0, 0, 0, 8]}
                  pb={[0, 0, 0, 8]}
                  backgroundColor="white"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <img
                    src={item.image.url}
                    alt={title}
                    style={{
                      width: isXsDown ? '100%' : '100%',
                      height: isXsDown ? '100%' : '100%',
                      //objectFit: 'cover',
                    }}
                  />
                </Box>
                <Box component={Grid} item xs={12} lg={7}>
                  <Box position="relative" height="100%">
                    <Box
                      position="absolute"
                      height="100%"
                      mt={8}
                      display={{ xs: 'none', lg: 'block' }}
                      style={{
                        background: 'white',
                        opacity: 0.2,
                        width: '60px',
                        height: '500px',
                      }}
                    />
                    <Container theme={theme} isXsDown={isXsDown}>
                      <Box mb={4}>
                        <Typography
                          component="h2"
                          variant="h1"
                          color="text.white"
                        >
                          Meet <br />
                          the Team.
                        </Typography>
                      </Box>
                      <Typography component="p" variant="h2" color="text.white">
                        {teamMemberName}
                      </Typography>
                      <Typography
                        component="p"
                        variant="body1"
                        color="text.white"
                        style={{ fontSize: '25px' }}
                      >
                        {jobTitle}
                      </Typography>
                      <Box
                        mt={8}
                        style={{
                          border: '4px solid white',
                          borderTopLeftRadius: '60px',
                          borderBottomRightRadius: '60px',
                          padding: '50px',
                          position: 'relative',
                        }}
                      >
                        <Box
                          position="absolute"
                          style={{
                            top: '0',
                            left: '50px',
                            transform: 'translate(0, -50%)',
                          }}
                          backgroundColor="#050a30"
                        >
                          <FormatQuoteIcon
                            style={{
                              color: 'white',
                              fontSize: '80px',
                              transform: 'rotate(180deg)',
                            }}
                          />
                        </Box>
                        <Typography
                          color="text.white"
                          component="p"
                          variant="body1"
                          fontStyle="italic"
                          align="center"
                        >
                          <RichTextField data={quote} />
                        </Typography>
                        <Box
                          position="absolute"
                          display={{ xs: 'none', lg: 'block' }}
                          style={{
                            bottom: '0',
                            right: '50px',
                            transform: 'translate(0, 50%)',
                          }}
                          backgroundColor="#050a30"
                        >
                          <FormatQuoteIcon
                            style={{ color: 'white', fontSize: '80px' }}
                          />
                        </Box>
                      </Box>
                    </Container>
                  </Box>
                </Box>
              </Grid>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

ImageCarousel.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        team_member_name: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]).isRequired,
        job_title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ImageCarousel;
